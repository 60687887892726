<template>
  <div class="container">
    <Header is-back>
      <template v-slot:title>
        <span>{{ $t('offlinePayment') }}</span>
      </template>
    </Header>
    <div class="main">
      <div class="reservation">
        <div class="block">
          <h2>{{ $t('bookingInformation') }}</h2>
          <el-row>
            <div>
              <span>{{ $t('bookingNumber') }}: </span>
              <span>{{ reservation.booking_number }} </span>
            </div>
            <div>
              <span>{{ $t('finalPrice') }}: </span>
              <span class="rate">{{ totalAmount | formatNumber }} </span>
            </div>
          </el-row>
        </div>

        <div v-if="paymentMethod === 'OfflinePayment'" class="block">
          <h2>{{ $t('offlinePayment2') }}</h2>
          <el-table
            :data="companies"
            border
            style="width: 100%">
            <el-table-column
              prop="currency"
              :label="$t('currency')"
              width="160"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="company"
              :label="$t('company')"
              width="300"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="bank"
              :label="$t('bank')"
              width="300"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="account"
              :label="$t('account')"
              width="240"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="swiftCode"
              :label="$t('swiftCode')"
              width="auto"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>

        <div class="note">
          {{ paymentNote }}
        </div>

        <div v-if="reservation.payment_status === 'Unpaid'" style="margin-top: 30px; display: flex; flex-direction: row; align-items: center;">
          <span class="cu-count-down">{{ day }}</span>
          <span>{{ $t('day') }}</span>
          <span class="cu-count-down">{{ hour }}</span>
          <span>{{ $t('hour') }}</span>
          <span class="cu-count-down">{{ minute }}</span>
          <span>{{ $t('minute') }}</span>
          <span class="cu-count-down">{{ second }}</span>
          <span style="padding-right: 8px;">{{ $t('second') }}</span>
        </div>

        <div v-if="paymentMethod === 'OfflinePayment'" style="padding-top: 10px;">
          <van-uploader
            style="width: 200px;"
            v-model="fileList"
            :max-count="1"
            :max-size="5000 * 1024"
            preview-size="200px"
            :after-read="afterRead"
            accept="image/png,image/jpeg"
            :preview-options="{closeable: true}"
            :disabled="time <= 0"
            @oversize="onOversize"
          />
          <div style="color: #515a6e;">{{ $t('upload file only for JPG/JPEG/PNG , and it is limited to 5MB') }}</div>
        </div>

        <div v-if="paymentMethod === 'OfflinePayment'" style="padding-bottom: 10px;">
          <el-input
            type="textarea"
            v-model="reservation.remark"
            rows="3"
            resize="none"
            maxlength="100"
            :placeholder="$t('paymentRemark')"
            show-word-limit
            style="border: 1px solid #dedede;"
          />
        </div>

        <div v-else style="display: flex; flex-direction: column; align-items: center;">
          <div style="padding: 8px;">{{ getTransactionDesc() }}</div>
          <div style="padding: 8px; color: #f3ab0f; font-weight: bold;">￥ {{ get(reservation, 'total_amount') | formatNumber }}</div>
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="width: 150px;">
            <g id="qrcode"/>
          </svg>
          <div v-if="get(reservation, 'payment_method') === 'WechatPay'" style="font-size: 14px; margin-top: 10px; display: flex; align-items: center;">
            {{ $t('Open mobile wechat to scan payment') }}<img src="/wechat_1.png" width="30" />
          </div>
          <div v-if="get(reservation, 'payment_method') === 'Alipay'" style="font-size: 14px; margin-top: 10px; display: flex; align-items: center;">
            {{ $t('Open mobile alipay to scan payment') }}<img src="/alipay_1.png" width="30" />
          </div>
          <div style="font-size: 14px; margin-top: 10px; color: red;">
            *{{ $t('Please do not pay repeatedly') }}*
          </div>
          <div style="font-size: 14px; margin-top: 10px; color: red;">
            *{{ $t('Please do not close the current page during payment') }}*
          </div>
        </div>

      </div>

      <div v-if="paymentMethod === 'OfflinePayment'" style="padding-bottom: 10px;">
        <el-button style="background-color: #005DA9; width: 300px; height: 50px; color: white;" :disabled="reservation.payment_status !== 'Unpaid' || time <= 0 || fileList.length < 1" @click="submit">{{ $t('submit') }}</el-button>
      </div>

      <div style="flex: 1;"></div>
      <Footer />
    </div>
  </div>
</template>

<script>
import dayjs from '@/utils/dayjs'
import get from 'get-value'
import { mapState } from 'vuex'
import Header from '@/components/Header'
import { formatNumber } from '@/filters'
import { Notify } from 'vant'
import Compressor from 'compressorjs'
import CountDown from '@/mixins/count-down'
import { countDown } from '@/utils'

export default {
  name: 'PublicPay',
  components: {
    Header
  },
  mixins: [CountDown],
  data () {
    return {
      reservation: {
        booking_number: '',
        total_amount: '',
        last_pay_time: '',
        payment_method: '',
        payment_status: '',
        currency_code: '',
        remark: '',
        hotel: null,
        roomType: null,
        nights: null
      },
      qrcode: null,
      jishiqi: null,
      paymentNote: '',
      fileList: [],
      companies: [
        {
          currency: '人民币（RMB）',
          company: '北京东方美景展览有限公司',
          bank: '中国农业银行股份有限公司北京惠新里支行',
          account: '11191101040016896',
          swiftCode: 'ABOCCNBJ010'
        },
        {
          currency: '美元（USD）',
          company: 'Beijing Oriental Magic Exhibition Co., Ltd',
          bank: 'Agricultural Bank of China Beijing Branch',
          account: '11191101040016896',
          swiftCode: 'ABOCCNBJ010'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      language: state => state.language
    }),
    time () {
      return dayjs(this.reservation.last_pay_time).diff(dayjs())
    },
    totalAmount () {
      if (this.reservation && this.reservation.currency_code) {
        return this.reservation.currency_code + formatNumber(this.reservation.total_amount)
      }

      return ''
    },
    paymentMethod () {
      return (this.reservation && this.reservation.payment_method) || null
    }
  },
  beforeDestroy () {
    clearInterval(this.jishiqi)
  },
  async mounted () {
    try {
      const res = await this.$axios.get(`/reservation/${this.$route.params.reservationId}/payment-info`)
      const { data: reservation } = await this.$axios.get(`/reservation/${this.$route.params.reservationId}`)

      if (res.data) {
        this.reservation = res.data
        if (reservation) {
          this.reservation.hotel = reservation.hotel
          this.reservation.roomType = reservation.roomType
          this.reservation.nights = reservation.nights
        }

        if (this.reservation.payment_method === 'NotSelected') {
          this.goPayment()
          return
        }
        const response = await this.$axios.get(`/payment-method/${this.reservation.payment_method}`)
        this.paymentNote = this.$store.state.language === 'en' ? response.data.note : response.data.note_cn
        await this.getQRCode()
        setInterval(() => {
          countDown.bind(this)(this.reservation.last_pay_time)
        }, 200)

        if (res.data.image) {
          this.fileList = [
            {
              url: res.data.image,
              deletable: this.reservation.payment_status === 'Unpaid',
              imageFit: 'contain'
            }
          ]
        }
      }
    } catch (e) {
      Notify(e.message)
    }
  },
  methods: {
    get,
    formatNumber,
    compressImg (file, options) {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-new
        new Compressor(file, {
          ...options,
          success (result) {
            resolve(result)
          },
          error (err) {
            reject(err)
          }
        })
      })
    },
    async afterRead (optons) {
      optons.status = 'uploading'
      const { file } = optons
      let result
      if (file.size > 1024 * 2000) { // 大于2MB, 自动压缩
        try {
          result = await this.compressImg(file, {
            convertSize: Infinity,
            maxWidth: 2048,
            maxHeight: 2048,
            quality: 0.6
          })
        } catch (e) {
          Notify(e.message)
        }
      }
      const formData = new FormData()
      formData.append('file', result || file, result ? result.name : file.name)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      try {
        const res = await this.$axios.post('/file/upload', formData, config)
        if (res && res.errCode === 0) {
          optons.status = 'done'
          optons.file_name = res.file_name
          optons.name = res.name
        }
      } catch (e) {
        console.error(e)
        optons.status = 'failed'
      }
    },
    onOversize (file) {
      Notify(this.$t('file size limit is {limit} MB', { limit: 5 }))
    },
    getTransactionDesc () {
      if (this.language === 'zh') {
        return get(this.reservation, 'hotel.name_cn', '') + ' - ' + get(this.reservation, 'roomType.name_cn', '') + ' - ' + get(this.reservation, 'nights', '') + '晚'
      }
      return get(this.reservation, 'hotel.name', '') + ' - ' + get(this.reservation, 'roomType.name', '') + ' - ' + get(this.reservation, 'nights', '') + 'Nights'
    },
    async getQRCode () {
      // eslint-disable-next-line no-undef
      this.qrcode = new QRCode(document.getElementById('qrcode'), {
        width: 100,
        height: 100,
        useSVG: true
      })

      if (this.reservation.payment_method === 'WechatPay') {
        const res = await this.$axios.post('/pay/wxqrcode', {
          id: this.reservation.id,
          description: this.getTransactionDesc(),
          amount: {
            total: this.reservation.total_amount,
            // total: 0.01, // 仅测试
            currency: 'CNY'
          }
        })

        this.qrcode.makeCode(res.data.code_url)
      } else if (this.reservation.payment_method === 'Alipay') {
        const res = await this.$axios.post('/pay/aliqrcode', {
          id: this.reservation.id,
          subject: this.getTransactionDesc(),
          total_amount: this.reservation.total_amount
          // total_amount: 0.01
        })

        this.qrcode.makeCode(res.data.qrCode)
      }

      this.jishiqi = setInterval(async () => {
        // 每5s查询一次订单状态.
        const res = await this.$axios.get(`/reservation/${this.reservation.id}`)
        if (res.data) {
          const resv = res.data
          if (resv.payment_status === 'Paid') {
            this.$router.push({
              name: 'BookingSuccess'
            })
          }
        }
      }, 5000)
    },
    goPayment () {
      this.$router.push({
        name: 'Payment',
        params: { reservationId: this.reservation.id }
      })
    },
    async submit () {
      if (this.fileList.length > 0) {
        try {
          if (!this.reservation.remark) {
            throw new Error(this.$t('paymentRemark'))
          }
          await this.$axios.put(`/reservation/${this.$route.params.reservationId}/payment-info`, {
            reservation_id: this.$route.params.reservationId,
            payment_method: 'OfflinePayment',
            file_name: this.fileList[0].file_name,
            remark: this.reservation.remark
          })

          this.$confirm(this.$t('do you need invoice'), this.$t('tips'), {
            type: 'warning'
          }).then(() => {
            this.$router.push({
              name: 'Invoice',
              params: { reservationId: this.$route.params.reservationId, to: 'BookingSuccess' }
            })
          }).catch(() => {
            this.$router.push({
              name: 'BookingSuccess'
            })
          })
        } catch (e) {
          Notify(e.message)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>

.main {
  padding-top: 64px;
  max-width: 1200px;
  margin: 0 auto;
}

.reservation {
  padding: 20px 0;
}

.note {
  color: #666;
  font-size: 1.1rem;
  margin-top: 2rem;
  line-height: 1.5em;
}

.colon {
  display: inline-block;
  margin: 0 4px;
  color: #ee0a24;
}

.clock-number {
  display: inline-block;
  width: 22px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background-color: #ee0a24;
}
</style>
