<template>
  <div :class="{ header: true, 'blue-background': $route.path !== '/hotel-list' }">
    <div class="title">
      <div class="left">
        {{ $t('web title') }}
      </div>
      <div class="right">
        <span class="my-menu" @click="goHotelList">{{ $t('accommodation') }}</span>
        <span class="my-menu" @click="goReservationList">{{ $t('bookings') }}</span>
        <el-popover
          placement="bottom"
          width="200"
          trigger="click"
          @show="menuShow"
          @hide="menuHide"
        >
          <div style="text-align: center;">
            <div>{{ $t('china') }}</div>
            <div class="gold">400-098-2996</div>
            <el-divider></el-divider>
            <div>{{ $t('overseas') }}</div>
            <div class="gold">hotel@bjome.com.cn</div>
<!--            <el-divider></el-divider>-->
<!--            <div>{{ $t('wechat') }}</div>-->
<!--            <div class="gold">-->
<!--              <img width="120" src="@/assets/wx_hotline.5e13630.jpg" />-->
<!--            </div>-->
          </div>
          <span class="my-menu" slot="reference">{{ $t('customerSupport') }} <i :class="showMenu ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i></span>
        </el-popover>
        <el-divider direction="vertical"></el-divider>
        <span class="my-menu" @click="logout">{{ $t('signOut') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { removeToken } from '@/utils/auth'

export default {
  name: 'Header',
  props: {
    isBack: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      showMenu: false
    }
  },
  methods: {
    menuShow () {
      this.showMenu = true
    },
    menuHide () {
      this.showMenu = false
    },
    goHotelList () {
      this.$router.push({ name: 'HotelList' }).catch(() => { window.location.reload() })
    },
    goReservationList () {
      this.$router.push({ name: 'ReservationList' }).catch(() => { window.location.reload() })
    },
    logout () {
      const _this = this

      this.$confirm(_this.$t('signOutConfirm'), _this.$t('signOut'))
        .then(() => {
          removeToken()
          _this.$router.push({ name: 'Home' })
        })
        .catch(() => {
          // on cancel
        })
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  box-sizing: border-box;
  padding: 20px 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 64px;
  z-index: 20;
}

.blue-background {
  background: #005DA9;
}

.my-menu {
  padding: 0 10px;
  margin: 0 15px;
  cursor: pointer;
}

.title {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.van-popup {
  -webkit-transform: unset !important;
  transform: unset !important;
}

.menus {
  padding: 0 20px;
  .menus-item {
    border-bottom: 1px solid white;
    padding: 20px 0;
    text-align: center;
    .gold {
      color: #FFD700;
    }
  }
}

.gold {
  padding-top: 10px;
  font-size: 20px;
}

</style>
